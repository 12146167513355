import React, { Component } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { SEO, Projects, Posts } from "src/components"
import Layout from "src/layouts"
import { config, projects } from "data"
import GitHubButton from "react-github-btn"

class IndexPage extends Component {
  render() {
    const { data } = this.props
    const latestPostEdges = data.latest.edges
    return (
      <Layout>
        <Helmet title={`${config.siteTitle} – Software Developer`} />
        <div className="home">
          <aside className="home-sidebar">
            <div className="home-sidebar-block">
              <div className="home-avatar">
                <img src={"profile-pic.jpg"} />
              </div>
              <div className="home-intro">{config.intro1}</div>
              <div className="home-intro">{config.intro2}</div>
              <div
                className="home-intro"
                dangerouslySetInnerHTML={{ __html: config.intro3 }}
              ></div>
              <GitHubButton
                href={config.github}
                data-size="large"
                aria-label="Follow @jamhall on GitHub"
              >{`Follow @${config.githubUsername}`}</GitHubButton>
              <div className="home-license">
                <div className="home-license-img">
                  <img src={"license.png"} />
                </div>
                <p dangerouslySetInnerHTML={{ __html: config.license }}></p>
              </div>
            </div>
          </aside>
          <main className="home-content">
            <section className="section">
              <div className="panel">
                <h2 className="panel-title">Latest Articles</h2>
                <div className="panel-body">
                  <Posts simple postEdges={latestPostEdges} />
                </div>
              </div>
            </section>
            <section className="section">
              <div className="panel">
                <h2 className="panel-title">Open Source Projects</h2>
                <div className="panel-body">
                  <Projects projects={projects} />
                </div>
              </div>
            </section>
          </main>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
            readingTime {
              text
              words
            }
          }
          excerpt
          timeToRead
          frontmatter {
            title
            description
            tags
            categories
            published
            thumbnail {
              childImageSharp {
                fixed(width: 40) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
